<template>
  <nav class="navbar navbar-expand-lg bg-light border-bottom">
    <div class="container-fluid">

      <router-link class="navbar-brand" :to="{name: 'home'}">
          <img src="@/assets/usedSoft.svg" style="height: 24px;position:relative; top: -4px;">
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t('menu.crm.title') }}
            </a>
            <ul class="dropdown-menu" v-if="$auth.check({crm: 'enable'})">
              <li><router-link class="dropdown-item" :to="{name: 'crm'}" active-class="active"><i class="fa-solid fa-magnifying-glass fa-fw me-2"></i> {{ $t('menu.crm.searchCompany') }}</router-link></li>
              <li><router-link class="dropdown-item" :to="{name: 'crmContacts'}" active-class="active"><i class="fa-solid fa-user-magnifying-glass fa-fw me-2"></i> {{ $t('menu.crm.searchContacts') }}</router-link></li>

              <li><router-link v-if="$auth.check({crm: 'searchShopusers'})" class="dropdown-item" :to="{name: 'crmShopUsers'}" active-class="active"><i class="fa-solid fa-cart-shopping fa-fw me-2"></i> {{ $t('menu.crm.searchShopusers') }}</router-link></li>

              <li><router-link class="dropdown-item" :to="{name: 'abc'}" active-class="active"><i class="fa-solid fa-chart-pyramid fa-fw me-2"></i> {{ $t('menu.crm.abcAnalysis') }}</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#" @click="$root.createCompany()">
                  <i class="fa-solid fa-plus  fa-fw me-2"></i> {{ $t('menu.crm.addCompany') }}
                  <span class="badge bg-secondary small ms-2">STRG + SHIFT + C</span>
              </a>
              </li>
              <template v-if="$auth.check({crm: 'editFlagging'})">
                <li><hr class="dropdown-divider"></li>
                <li>
                    <router-link class="dropdown-item position-relative" :to="{name: 'flagging'}" active-class="active"><i class="fa-solid fa-flag fa-fw me-2"></i> {{ $t('menu.crm.editFlagging') }} <span v-if="$store.state.notifications.counts.flaggingCount > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{ $store.state.notifications.counts.flaggingCount }}</span></router-link>
                </li>
              </template>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="$auth.check({erp: 'enable'})">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ $t('menu.mms.title') }}
              </a>
              <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" :to="{name: 'offers'}" active-class="active"><i class="fa-solid fa-handshake fa-fw me-2"></i> {{ $t('menu.mms.salesOffers') }}</router-link></li>
                  <li><a href="#" class="dropdown-item" @click="$root.createOffer('offer')"><i class="fa-solid fa-plus  fa-fw me-2"></i> {{ $t('menu.mms.createSalesOffer') }}</a></li>
                  <!--<li><router-link class="dropdown-item" :to="{name: 'offer'}" active-class="active"><i class="fa-solid fa-plus  fa-fw me-2"></i> {{ $t('menu.mms.createSalesOffer') }}</router-link></li>-->
                  <template v-if="$auth.check({erp: 'releaseOffers'})">
                      <li><hr class="dropdown-divider"></li>
                      <li><router-link class="dropdown-item position-relative" :to="{name: 'releaseOffers'}" active-class="active"><i class="fa-solid fa-tower-control fa-fw me-2"></i> {{ $t('menu.mms.releaseSalesOffer') }} <span v-if="$store.state.notifications.counts.releasesCount > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{ $store.state.notifications.counts.releasesCount }}</span></router-link></li>
                  </template>
                  <template v-if="$auth.check({erp: 'processOrders'})">
                    <li><hr class="dropdown-divider"></li>
                    <li><router-link class="dropdown-item" :to="{name: 'orders'}" active-class="active"><i class="fa-solid fa-arrow-progress fa-fw me-2"></i> {{ $t('menu.mms.orderProcessing') }}</router-link></li>
                  </template>
                  <template v-if="$auth.check({erp: 'processOrders'})">
                    <li><hr class="dropdown-divider"></li>
                    <li><router-link class="dropdown-item" :to="{name: 'archive'}" active-class="active"><i class="fa-solid fa-box-archive fa-fw me-2"></i> Archivierte Aufträge</router-link></li>
                  </template>
              </ul>
          </li>
          <li class="nav-item dropdown" >
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ $t('menu.store.title') }}
              </a>
              <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" :to="{name: 'articles'}" active-class="active"><i class="fa-regular fa-file-certificate  fa-fw me-2"></i> {{ $t('menu.store.itemManagement') }}</router-link></li>
                  <template v-if="$auth.check({store: 'enable'})">
                    <li><hr class="dropdown-divider"></li>
                    <li><router-link class="dropdown-item" :to="{name: 'articleOrders'}" active-class="active"><i class="fa-solid fa-bag-shopping  fa-fw me-2"></i> {{ $t('menu.store.itemOrder') }}</router-link></li>
                    <li><a class="dropdown-item" href="#" @click="$root.createArticleorder()"><i class="fa-solid fa-plus  fa-fw me-2"></i> {{ $t('menu.store.createItemOrder') }}</a></li>
                  </template>
              </ul>
          </li>
          <li class="nav-item dropdown" v-if="$auth.check({fibu: 'enable'})">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ $t('menu.accounting.title') }}
              </a>
              <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" :class="[$auth.check({fibu: 'showAccount'}) ? '' : 'disabled']" :to="{name: 'invoices'}" active-class="active"><i class="fa-solid fa-file-invoice fa-fw me-2"></i> {{ $t('menu.accounting.invoices') }}</router-link></li>
                  <li><router-link class="dropdown-item" :class="[$auth.check({fibu: 'showAccount'}) ? '' : 'disabled']" :to="{name: 'provisions'}" active-class="active"><i class="fa-solid fa-droplet-percent fa-fw me-2"></i> {{ $t('menu.accounting.commissionManagement') }}</router-link></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><router-link class="dropdown-item" :class="[$auth.check({fibu: 'showAccount'}) ? '' : 'disabled']" :to="{name: 'account'}" active-class="active"><i class="fa-solid fa-building-columns fa-fw me-2"></i> {{ $t('menu.accounting.accountOverview') }}</router-link></li>
              </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.usedsoft.com" target="_blank"><i class="fa-solid fa-cart-shopping text-primary"></i> Shop</a>
          </li>
            <li class="nav-item" v-if="$auth.check({dev: 'enable'})">
                <router-link :to="{name:'dev'}" class="nav-link"><i class="fa-solid fa-microscope text-danger"></i></router-link>
            </li>
        </ul>
        <div class="col-3 px-2" role="search">
          <auto-suggest></auto-suggest>
        </div>
        <menu-notifications />
        <menu-reminders />
        <menu-phone />
        <menu-login  v-if="$root.debug"/>






          <div class="dropdown border-start">
            <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <strong class="text-uppercase">{{ $i18n.locale}}</strong> {{process}}
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small shadow" style="">
              <li><a class="dropdown-item" :class="[$i18n.locale == 'de' ? 'active' : '']" @click="$root.setLocale('de')">DE | Deutsch</a></li>
              <li><a class="dropdown-item" :class="[$i18n.locale == 'en' ? 'active' : '']" @click="$root.setLocale('en')">EN | English</a></li>
              <li><a class="dropdown-item" :class="[$i18n.locale == 'it' ? 'active' : '']" @click="$root.setLocale('it')">IT | Italiano</a></li>
            </ul>
          </div>
          <div class="dropdown border-start" v-if="$auth.check({setting: 'enable'})">
              <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa-solid fa-gears"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end text-small shadow" style="">
                  <li><router-link class="dropdown-item" :to="{name: 'users'}" active-class="active"><i class="fa-solid fa-users fa-fw me-2"></i>{{ $t('menu.globalSettings.userManagement') }}</router-link></li>
                  <li><a class="dropdown-item disabled" href="#"><i class="fa-solid fa-calendar-heart fa-fw me-2"></i> {{ $t('menu.globalSettings.holidayManagement') }}</a></li>
              </ul>
          </div>
          <div class="dropdown border-start">
            <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <div style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover;" :style="{'background-image': 'url(' + $filters.image($auth.user().profilImage, 'thumb') + ')'}"  class="d-inline-block rounded-circle"></div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small shadow" style="">
              <template v-if="$auth.user().userrole_id != 3">
                <li>
                  <router-link :to="{name:'employerReport', params:{id: $auth.user().id}}" class="dropdown-item"><i class="fa-solid fa-chart-line fa-fw me-2"></i> Statistik</router-link>
                </li>
                <li><hr class="dropdown-divider"></li>
              </template>

              <li><a class="dropdown-item disabled" href="#"><i class="fa-solid fa-sliders fa-fw me-2"></i> {{ $t('menu.user.settings') }}</a></li>
              <li><a class="dropdown-item" @click="showModalPassword=true" href="#"><i class="fa-solid fa-lock fa-fw me-2"></i> {{ $t('menu.user.changePassword') }}</a></li>
              <li><a class="dropdown-item" @click="showModalAutoresponder=true" href="#"><i class="fa-solid fa-envelope fa-fw me-2"></i> {{ $t('menu.user.autoresponder') }}</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" @click="$root.logout()" href="#"><i class="fa-solid fa-right-from-bracket fa-fw me-2"></i> {{ $t('menu.user.logout') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <modal-change-password v-model:shown="showModalPassword" />
      <modal-enable2-fa v-model:shown="showModal2fa" />
      <modal-autoresponder v-model:shown="showModalAutoresponder" />
    </nav>
  </template>

  <script>
  import AutoSuggest from "@/components/autoSuggest.vue";
  //import modalCompany from "@/pages/crm/modalCompany.vue";
  import modalChangePassword from "@/pages/auth/modalChangePassword.vue";
  import modalAutoresponder from "@/pages/common/modalAutoresponder.vue";
  import MenuNotifications from "@/components/menuNotifications.vue";
  import MenuReminders from "@/components/menuReminders.vue";
  import MenuPhone from "@/components/menuPhone.vue";
  import MenuLogin from "@/components/menuLogin.vue";
  import moment from "moment";
  import ModalEnable2Fa from "@/pages/auth/modalEnable2Fa.vue";

  export default {
    name: "menuTop",
      computed: {
          /*modalCompany() {
              //return modalCompany
          }*/
      },
      components: {
        ModalEnable2Fa,
        MenuLogin,
        MenuPhone, MenuReminders, MenuNotifications, AutoSuggest, modalChangePassword, modalAutoresponder},
    data(){
        return {
            showModalPassword: false,
            showModal2fa: false,
            showModalAutoresponder: false,
        }
    },
    mounted() {
        this.checkLastPasswordChange();
    },
    methods: {
        checkLastPasswordChange(){
          if(!this.$auth.check()) return;

          if(!this.$auth.user().lastPasswordChange){
            this.showModalPassword = true;
            return;
          }

          let now = moment();
          let duration = moment.duration(now.diff(this.$auth.user().lastPasswordChange));
          let months = duration.asMonths();

          if(months > 6){
            this.showModalPassword = true;
            return;
          }

          if(!this.$auth.user().is2faEnabled){
            this.showModal2fa = true;
          }

          return;
        },
        dial(number){
          this.axios.post('call/init', {target: number}).then(res => {
              this.$toast.success('Anruf eingeleitet');
          });
        },
    }
  }
  </script>

  <style scoped>

  </style>
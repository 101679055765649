<script>

import eventBus from "@/eventBus";


export default {
  name: "menuPhone",
  data() {
    return {
      activeCall: false,
      activeCallInfo: {
        company: "RGI GmbH",
        contact: "Michael Bitter",
        company_id: 1,
        contact_id: 1,
        start: new Date().toUTCString(),
        elapsedTime: '00:00'
      },
    };
  },
  mounted() {

    /*eventBus.$on('call', (call) => {
        console.log('call');
       this.activeCallInfo = call.call;
       this.activeCallInfo.elapsedTime = '00:00';
       this.activeCall = true;
    });*/

    /*let timer = setInterval(() => {
      //this.activeCall = !this.activeCall;
      this.activeCall = true;
    }, 5000);*/

    let timeUpdateInterval = setInterval(() => {
      if(this.activeCall){
        this.updateElapsedTime();
      }
    }, 1000);  // Alle 1 Sekunde aktualisieren
  },
  methods: {
    getClass(call) {
      let $class = 'fa-phone ';

      if (call.direction == 'in') $class = 'fa-phone-arrow-right ';

      if (call.intern && this.$auth.user().id == call.user_id) $class = 'fa-phone ';
      if (call.intern && this.$auth.user().id == call.interUser_id) $class = 'fa-phone-arrow-right ';

      if (call.eventHungUpType == 'missed') $class = 'fa-phone-missed ';
      if (call.eventHungUpType == 'canceled') $class = 'fa-phone-missed ';
      if (call.eventHungUpType == 'busy') $class = 'fa-phone-missed ';

      if (call.eventHungUpType == 'accepted') $class += ' text-success';
      if (call.eventHungUpType == 'canceled') $class += ' text-info';
      if (call.eventHungUpType == 'busy') $class += ' text-danger';
      if (call.eventHungUpType == 'missed') $class += ' text-danger';
      if (call.eventHungUpType == 'canceled') $class += ' text-danger';

      return $class;
    },
    elapsedTime(utcString) {
      const startDate = new Date(utcString);
      const now = new Date();
      const diffMs = now - startDate;
      const minutes = Math.floor(diffMs / 60000);
      const seconds = Math.floor((diffMs % 60000) / 1000);
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    updateElapsedTime() {
        this.activeCallInfo.elapsedTime = this.elapsedTime(this.activeCallInfo.start);
    }
  }
}
</script>

<template>
  <div class="dropdown border-start">
    <a href="#"
       class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle"
       data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa-solid fa-phone"></i>
      <Transition name="slide-fade">
        <div v-if="activeCall" class="ms-2 d-inline-block text-center alert alert-success p-0 px-1 m-0" style="font-size: 0.75em;">
          <strong>{{ activeCallInfo.elapsedTime }}</strong>
          <br>
          <span>{{ activeCallInfo.contact }}, {{ activeCallInfo.company }}</span></div>
      </Transition>
    </a>
    <div class="dropdown-menu dropdown-menu-end text-small shadow py-0 overflow-hidden" style="min-width: 30vw;">
      <div class="row">
        <div class="col-7 pe-0">
          <div class="small text-muted p-2" v-if="$store.state.phone.length == 0">Keine Anrufe vorhanden</div>
          <ul class="list-group list-group-flush" v-else>
            <template v-for="call in $store.state.phone" :key="call.id">
              <li class="list-group-item list-group-item-action" :title="call.call_id">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <i class="fa-solid" :class="getClass(call)"></i>
                  </div>
                  <template v-if="call.intern">
                    <div class="col text-end" v-if="call.user_id != $auth.user().id">{{ call.from }}</div>
                    <div class="col text-end" v-else>{{ call.to }}</div>
                  </template>
                  <template v-else>
                    <div class="col text-end" v-if="call.direction == 'in'">{{ call.from }}</div>
                    <div class="col text-end" v-else>{{ call.to }}</div>
                  </template>
                </div>
                <div class="row align-items-center flex-nowrap"
                     v-if="call.intern && call.user_id == $auth.user().id && call.intern_user">
                  <div class="col">{{ call.intern_user.name }}</div>
                  <div class="col-auto">
                    <div
                        style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;"
                        :style="{'background-image': 'url(' + $filters.image(call.intern_user.profilImage, 'thumb') + ')'}"
                        :class="[$online.isOnline(call.intern_user)? 'border-success' : 'border-light']"
                        class="d-block rounded-circle border bg-white shadow-sm"></div>
                  </div>
                </div>
                <div class="row align-items-center flex-nowrap"
                     v-else-if="call.intern && call.internUser_id == $auth.user().id && call.user">
                  <div class="col">{{ call.user.name }}</div>
                  <div class="col-auto">
                    <div
                        style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;"
                        :style="{'background-image': 'url(' + $filters.image(call.user.profilImage, 'thumb') + ')'}"
                        :class="[$online.isOnline(call.user)? 'border-success' : 'border-light']"
                        class="d-block rounded-circle border bg-white shadow-sm"></div>
                  </div>
                </div>
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto small text-muted" style="font-size: 0.8rem;">
                    {{ $filters.humanize(call.eventStart) }}
                  </div>
                  <div class="col small text-muted text-end" style="font-size: 0.8rem; font-weight: bold;"
                       v-if="call.duration">{{ $filters.secondsToTime(call.duration) }}
                  </div>
                  <div class="col small text-muted text-end" style="font-size: 0.8rem; font-weight: bold;" v-else>
                    {{ call.eventHungUpType }}
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="col-5 border-start ps-0">
          <div class="list-group list-group-item-light list-group-flush">
            <template v-for="user in $store.state.users" :key="user.id">

              <a class="list-group-item list-group-item-action" v-if="user.placetel && user.id != $auth.user().id"
                 href="#" @click="dial(user.placetel.searchKey3)">
                <div class="row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <div
                        style="width: 28px; height: 28px; background: transparent no-repeat top center; background-size: cover; border-width: 3px !important;"
                        :style="{'background-image': 'url(' + $filters.image(user.profilImage, 'thumb') + ')'}"
                        :class="[$online.isOnline(user)? 'border-success' : 'border-light']"
                        class="d-block rounded-circle bg-white shadow-sm"></div>
                  </div>
                  <div class="col">{{ user.name }}</div>
                </div>
              </a>

            </template>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
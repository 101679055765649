<script>
export default {
  name: "menuReminders",
  data(){
      return {
        selectedAction: null,
        showAction: false,
      }
  },
  methods: {
    openAction(action) {
      this.selectedAction = action;
      this.showAction = true;
    },
    checkReminder(action){
        if(!confirm("Erinnerung als erledigt markieren?")) return;

        let url = `crm/actions/${action.id}/unsetReminder`;

        this.axios.put(url).then(res => {
            if(res.data.success){
                this.$root.loadReminders();
            }
        });
    },
    goToRef(action){
      switch(action.ref_type){
        case 'offer':
          this.$router.push({name: 'offer', params: {id: action.ref_id}});
          break;
        case 'order':
          this.$router.push({name: 'order', params: {id: action.ref_id}});
          break;
        case 'invoice':
          this.$router.push({name: 'invoice', params: {id: action.ref_id}});
          break;
        default:
          alert('unbekannte Referenz');
      }
    },
  }
}
</script>

<template>
  <div class="dropdown border-start" style="height: ">
    <a href="#" class="d-flex align-items-center justify-content-center px-2 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa-solid fa-bell"><span v-if="$store.state.reminder.reminders.length > 0" class="position-absolute bottom-100 right-0 badge rounded-pill bg-danger">{{ $store.state.reminder.reminders.length }}</span></i>
    </a>
    <div class="dropdown-menu dropdown-menu-end text-small shadow p-0 overflow-hidden" style="min-width: 15vw;">
      <p class="p-3 m-0" v-if="$store.state.reminder.reminders.length == 0 && $store.state.reminder.next.length == 0">{{ $t('notifications.noData') }}</p>
      <ul class="list-group list-group-flush m-0" v-else>

          <template v-for="action in $store.state.reminder.reminders" :key="action.id">
            <li class="list-group-item list-group-item-action" @click="$router.push({name: 'company', params: {id: action.company_id}})" style="cursor: pointer;">
              <div class="position-absolute top-0 end-0 me-1 mt-1">
                <button class="btn btn-primary btn-xs" @click.stop="goToRef(action)" v-if="action.ref_type"><i  class="fa-solid fa-arrow-right"></i></button>
                <button class="btn btn-success btn-xs ms-1" title="Erinnerung als erledigt markieren" @click.stop="checkReminder(action)"><i class="fa-solid fa-check" ></i></button>
              </div>
              <div class="">
                <div>
                  <i class="fa-solid fa-envelope fa-fw text-primary me-2"
                     v-if="action.actionType == 4"></i>
                  <i class="fa-solid fa-phone-office fa-fw text-primary me-2"
                     v-else-if="action.actionType == 9"></i>
                  <i class="fa-solid fa-handshake text-warning-emphasis fa-fw me-2"
                     v-else-if="action.actionType == 1"></i>
                  <i class="fa-solid fa-file-invoice-dollar text-success fa-fw me-2"
                     v-else-if="action.actionType == 2"></i>
                  <i class="fa-solid fa-calendar-clock text-danger fa-fw me-2"
                     v-else-if="action.actionType == 10"></i>
                  <i class="fa-solid fa-bolt fa-fw text-secondary me-2" v-else></i>
                  <b>{{ action.type.translated }}</b>

                </div>
                <div class="small">{{action.title}}</div>
                <div class="small text-muted " v-if="action.reminderDate">
                  <span class="text-danger"><i class="fa-solid fa-alarm-clock"></i> Erinnerung am {{$filters.date(action.reminderDate)}}</span>
                </div>
                <div class="small text-muted d-flex w-100 justify-content-between">
                  <span>{{ $filters.date(action.actionDate) }}</span>
                  <span v-if="action.creator">{{ action.creator.name }}</span>
                </div>
              </div>

            </li>
          </template>
        <li class="list-group-item bg-secondary text-white small" v-if="$store.state.reminder.next && $store.state.reminder.next.length > 0">bevorstehende Erinnerungen:</li>
        <template v-for="action in $store.state.reminder.next" :key="action.id">
          <li class="list-group-item list-group-item-action" @click="$router.push({name: 'company', params: {id: action.company_id}})" style="cursor: pointer;">
            <div class="btn btn-success btn-xs position-absolute top-0 end-0 me-1 mt-1" @click.stop="checkReminder(action)" title="Erinnerung als erledigt markieren"><i class="fa-solid fa-check"></i></div>
            <div class="">
              <div>
                <i class="fa-solid fa-envelope fa-fw text-primary me-2"
                   v-if="action.actionType == 4"></i>
                <i class="fa-solid fa-phone-office fa-fw text-primary me-2"
                   v-else-if="action.actionType == 9"></i>
                <i class="fa-solid fa-handshake text-warning-emphasis fa-fw me-2"
                   v-else-if="action.actionType == 1"></i>
                <i class="fa-solid fa-file-invoice-dollar text-success fa-fw me-2"
                   v-else-if="action.actionType == 2"></i>
                <i class="fa-solid fa-calendar-clock text-danger fa-fw me-2"
                   v-else-if="action.actionType == 10"></i>
                <i class="fa-solid fa-bolt fa-fw text-secondary me-2" v-else></i>
                <b>{{ action.type.translated }}</b>
                <i v-if="action.ref_type" class="fa-solid fa-square-arrow-right float-end link-primary" @click.stop="goToRef(action)" style="cursor: pointer"></i>
              </div>
              <div class="small">{{action.title}}</div>
              <div class="small text-muted " v-if="action.reminderDate">
                <span class="text-danger"><i class="fa-solid fa-alarm-clock"></i> Erinnerung am {{$filters.date(action.reminderDate)}}</span>
              </div>
              <div class="small text-muted d-flex w-100 justify-content-between">
                <span>{{ $filters.date(action.actionDate) }}</span>
                <span v-if="action.creator">{{ action.creator.name }}</span>
              </div>
            </div>

          </li>
        </template>

      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
<template>
  <main id="appwrapper" class="h-100" @keydown="keys" :class="[hideModal ? 'modalHidden' : '']">
    <Transition>
      <form-login v-if="!$auth.check()"></form-login>
    </Transition>
      <!--<button @click="toastIt()">ToastIt</button>-->
      <menu-top v-if="$auth.check()"></menu-top>
      <div style="position: absolute; bottom: 0; left: 0; right: 0; top: 57px; "  v-if="$auth.check()">
        <div class="d-flex flex-nowrap flex-column h-100" style="min-height: 0px;">
          <!--<menu-sidebar  v-if="$auth.check()"></menu-sidebar>-->
          <div class="alert alert-danger rounded-0 mb-0 border-0 text-center" v-if="!upToDate">
            <div class="row align-items-center justify-content-center">
            <div class="col-auto text-end">
              <i class="fa-solid fa-triangle-exclamation text-primary fa-2x"></i>
            </div>
            <div class="col-auto text-start" v-html="$t('common.notUpToDate')"></div>
            </div>
          </div>
          <div class="flex-grow-1" style="min-height: 0;">
              <router-view v-slot="{ Component, route }">
                <tab v-if="$auth.check() && false"  />
                <component
                    :is="Component"
                />
              </router-view>
          </div>
        </div>
      </div>

      <modal-company v-model:shown="modalCompany" :modus="modalCompanyModus"/>
      <modal-article v-model:shown="modalArticle" :modus="modalArticleModus"/>
      <modal-flagging v-model:shown="modalFlagging" :company="modalFlaggingCompany"/>
      <modal-comment v-model:shown="modalComment" :modus="modalCommentModus" />


      <div id="modalBackButton" @click="hideModal=false" class="fa-2x" :class="[hideModal ? 'fa-bounce': '']" style="--fa-animation-duration: 3s;">
          <i class="fa-regular fa-window-maximize"></i>
      </div>
  </main>
</template>

<script>
import axios from "axios";

import MenuSidebar from "@/components/menuSidebar";
import formLogin from "@/pages/auth/formLogin.vue";
import MenuTop from "@/components/menuTop.vue";

import { provideToast, useToast } from "vue-toastification";
import toastOfferDecision from "@/components/toasts/toastOfferDecision.vue";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { useKeypress } from "vue3-keypress";
import { ref } from "vue";
import ModalCompany from "@/pages/crm/modalCompany";
import ModalFlagging from "@/pages/crm/modalFlagging";

import eventBus from "@/eventBus";

import {i18n} from "@/main";
import {setI18nLanguage} from "@/i18n";
import ModalArticle from "@/pages/article/modalArticle.vue";
import Tab from "@/components/Tab.vue"
import ModalComment from "@/pages/common/modalComment.vue";



export default {
  name: 'App',
  components: {
    ModalComment,
      ModalArticle,
      ModalFlagging,
      ModalCompany,
    MenuTop,
    formLogin,
    MenuSidebar,
    Tab,
    toastOfferDecision
  },
  watch:{
    '$i18n.locale'(locale){
      axios.defaults.params = {'lang': this.$i18n.locale};

      //Prüfen ob localStorage verfügbar ist
      if(window.localStorage || 0){
        localStorage.setItem('languageSetting', this.$i18n.locale);
      }
      if(this.$auth.ready() && this.$auth.check()){
        this.loadConfig();
      }

    }
  },
  data(){
    return {
      debug: false,
      newVersionHint: false,
      version: "202409120600",
      upToDate: true,
      hideModal: false,
      config: {
        url: "https://rewrite.system.usedsoft.de/api/",
        //url: "https://dev.system.usedsoft.de/api/",
        debug: false,
        staging: false,
      },
      modalCompany: false,
      modalCompanyModus: {},
      modalFlagging: false,
      modalFlaggingCompany: null,
      modalArticle: false,
      modalArticleModus: {},
      modalComment: false,
      modalCommentModus: {},
    }
  },
  computed: {

  },
  setup(){

      provideToast({ maxToasts: 5 });
      const toast = useToast();
      const someAnyKeyCallback = (event) => {

      }

      useKeypress({
          keyEvent: "keydown",
          keyBinds: [
              {
                  keyCode: "left", // or keyCode as integer, e.g. 37
                  modifiers: ['ctrlKey'],
                  success: ({event}) => {
                      eventBus.$emit('movePrev');
                  } ,
                  preventDefault: true, // the default is true
              },
              {
                  keyCode: "right", // or keyCode as integer, e.g. 37
                  modifiers: ['ctrlKey'],
                  success: ({event}) => {
                      eventBus.$emit('moveNext');
                  } ,
                  preventDefault: true, // the default is true
              },
              {
                  keyCode: 67, // or keyCode as integer, e.g. 37
                  modifiers: ['shiftKey','ctrlKey'],
                  success: ({event}) => {
                      eventBus.$emit('newCompany');
                  } ,
                  preventDefault: true, // the default is true
              },
              {
                  keyCode: 88, // or keyCode as integer, e.g. 37
                  modifiers: ['shiftKey','ctrlKey'],
                  success: ({event}) => {
                      eventBus.$emit('hideModal');
                  } ,
                  preventDefault: true, // the default is true
              },
          ],
          onAnyKey: someAnyKeyCallback,
          /*onWrongKey: someWrongKeyCallback,
          onAnyKey: someAnyKeyCallback,
          isActive: isActiveRef,*/
      });

      return { toast };
  },
  mounted() {

    axios.defaults.baseURL = this.config.url;
    axios.defaults.params = {'lang': "de"};

    this.debug = (process.env.NODE_ENV == 'development');

    let updateInterval = setInterval(() => {
      this.checkVersion();
    }, 300000);
    this.checkVersion();




    // Add a response interceptor
   /* axios.interceptors.response.use((response) => {

      if(response.headers['x-app-version'] > this.version && !this.newVersionHint){
        this.newVersionHint = true;

        alert('Bite aktualisiere den Browser ( F5-Taste ). Es liegt eine neue Programmversion vor. Diese ist nicht mehr aktuell.');
        this.$toast.error('Neue Version liegt vor.');
      }

      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });*/

    if(window.localStorage || 0){
      let languageSetting = localStorage.getItem('languageSetting');

      if(languageSetting){
        this.setLocale(languageSetting);
      }
    }

    this.$store.commit("tabbar/setRouter", this.$router);
    this.$store.commit("tabbar/initialiseStore");

      let timeout = setInterval(() => {
          if(this.$auth.ready()){
              clearInterval(timeout);
          }

          if(this.$auth.check()){
              this.init();
          }
      }, 300);

      eventBus.$on('newCompany', () =>{
          this.createCompany();
      });
      eventBus.$on('hideModal', () =>{
          this.setModalHidden();
      });

  },
  unmounted() {
      eventBus.$off('newCompany');
      eventBus.$off('hideModal');
  },
  methods: {
      checkVersion(){
        this.axios.get('version').then(res => {
          if(res.data > this.version){
            this.upToDate = false;
          }
        });
      },
      toastIt(){
        this.toast({
          component: toastOfferDecision,
          props:  {
            //propName: propValue  // Optional
          },
          listeners: {
            goForIt: () => {alert('thunderbirds are going')}  // Optional
          },

        },{
          timeout: false
        });
      },
      keys(e){
          //console.log(e);
      },
      logout(){
          this.$auth.logout({
              makeRequest: true,
              redirect: {name: 'login'},
          });

          this.$toast.success(this.$t('login.logout'));
      },
      init(){
          this.initWebsocket();
          this.loadConfig();
          this.loadStatus();
          this.loadNotifications();
          this.loadReminders();
          this.loadCalls();
          //this.loadUsers();
          //this.loadBranches();
      },
      createCustomer(){
          alert('Funktion ist gesperrt');
      },
      createCompany(){
          this.modalCompanyModus = {
              modus: "company",
              action: "create",
          };
          this.modalCompany = true;
      },
      editCompany(company_id){
          this.modalCompanyModus = {
              modus: "company",
              action: "edit",
              company_id: company_id
          };
          this.modalCompany = true;
      },
      createAddress(company_id, defaultCountry){
          this.modalCompanyModus = {
              modus: "address",
              action: "create",
              company_id: company_id,
              defaultCountry: defaultCountry
          };
          this.modalCompany = true;
      },
      editAddress(address_id){
          this.modalCompanyModus = {
              modus: "address",
              action: "edit",
              address_id: address_id
          };
          this.modalCompany = true;
      },
      createContact(address_id, defaultCountry){
          this.modalCompanyModus = {
              modus: "contact",
              action: "create",
              address_id: address_id,
              defaultCountry: defaultCountry
          };
          this.modalCompany = true;
      },
      editContact(contact_id){
          this.modalCompanyModus = {
              modus: "contact",
              action: "edit",
              contact_id: contact_id
          };
          this.modalCompany = true;
      },
      createArticle(){
          this.modalArticleModus = {
              action: "create",
          };
          this.modalArticle = true;
      },
      editArticle(article_id){
          this.modalArticleModus = {
              action: "edit",
              article_id: article_id,
          };
          this.modalArticle = true;
      },
      createFlagging(company){
          this.modalFlaggingCompany = company;
          this.modalFlagging = true;
      },
      initCall(target){
          if(confirm('Nummer wählen')){
            this.axios.post('call/init', {target: target}).then(res => {
                this.$toast.success('Anruf eingeleitet');
            });
          }
          else{
              this.$toast.error('Anruf abgebrochen');
          }
      },
      loadConfig(){
          let url = "config"
          this.axios.get(url).then(res => {
              this.$store.commit('setConfig', res.data);
          });
      },
      loadStatus(){
          let url = "system/status"
          this.axios.get(url).then(res => {
              this.$store.commit('setStatus', res.data);
          });
      },
      loadNotifications(){
        let url = "system/notifications"
        this.axios.get(url).then(res => {
          this.$store.commit('setNotifications', res.data);
        });
      },
      loadReminders(){
        let url = "system/reminders"
        this.axios.get(url).then(res => {
          this.$store.commit('setReminders', res.data);
        });
      },
      loadCalls(){
        let url = "system/calls"
        this.axios.get(url).then(res => {
          this.$store.commit('setCalls', res.data);
        });
      },
      loadBranches(){
          let url = "crm/activeBranchesList"
          this.axios.get(url).then(res => {
              this.$store.commit('setBranches', res.data);
          });
      },
      loadUsers(){
          let url = "users/activeUsersList"
          this.axios.get(url).then(res => {
              this.$store.commit('setUsers', res.data);
          });
      },
      isInRoute(name){
          if(this.$router.currentRoute.name == 'crm') return true;
          //alert(this.$router.currentRoute);
          return false;
      },
      loginUser(user){
        this.disconnectWebsockets();

        this.axios.post("auth/changeUser", {user: user}).then(res => {
          let oldUser = this.$auth.user();

          if(res.data.status == "success") {

            this.$auth.token(null, res.data['_token']);

            this.$auth.fetch().then(() => {
              this.$toast.success('Login erfolgreich! Sie sind nun als  <strong>' + this.$auth.user().name + "</strong> eingeloggt.");
              this.$store.commit("tabbar/initialiseStore");
              this.initWebsocket();
              this.$router.push({name: 'home'});
            });
          }
          else{
            this.$toast.error('Login ist fehlgeschlagen.');
          }
        }).catch(error => {
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        });
      },
      disconnectWebsockets(){
        window.Echo.disconnect();
      },
      initWebsocket(){
          window.Echo = new Echo({
              broadcaster: 'pusher',
              key: 'd3e79142d2a29671bf5b',
              cluster: "eu",
              forceTLS: true,
              authorizer: (channel, options) => {
                  return {
                      authorize: (socketId, callback) => {
                          axios.post('broadcasting/auth', {
                              socket_id: socketId,
                              channel_name: channel.name
                          })
                          .then(response => {
                              callback(null, response.data);
                          })
                          .catch(error => {
                              callback(error);
                          });
                      }
                  };
              },
          });

          window.Echo.channel('updates').listen(".message.send", (e) => {
              this.$toast.info(e.message + "_!1!_");
          });

          window.Echo.private('updates').listen(".message.send", (e) => {
              //alert(e.message);
              this.$toast.info(e.message + "_!2!_");
          }).listenToAll((event, data) => {
              // do what you need to do based on the event name and data
              console.log('Listen to all Events: ',event, data);
          });

          window.Echo.private('user.' + this.$auth.user().id)
          .listen(".message.send", (e) => {
            //alert(e.message);
            this.$toast.info(e.message);
          })
          .listen(".phone.call", (e, data) =>{
              eventBus.$emit('call', e);
              //this.$toast.info("Irgendwas am Telefon: " + e.type);
              //this.$toast.info(e.type);
          })
          .listen(".offer.release", (data, e ) => {
            let options = {
              component: toastOfferDecision,
              props:  {
                data: data  // Optional
              },
              listeners: {
                goForIt: () => {
                  this.$router.push({name: 'offer', params: {id : data.id}});
                }  // Optional
              },

            };

            if(data.status == 'released'){
              this.toast.success(options,{
                timeout: false
              });
            }
            else{
              this.toast.error(options,{
                timeout: false
              });
            }
          })
          .listenToAll((event, data) => {
            // do what you need to do based on the event name and data
            console.log('User Channel ... Listen to all Events: ',event, data);
          });

          if(this.$auth.check({crm: 'editFlagging'})){
            window.Echo.private('flagging').listenToAll((event, data) => {
              // do what you need to do based on the event name and data
              this.loadNotifications();
              eventBus.$emit('flagging');
            });
          }
          if(this.$auth.check({erp: 'releaseOffers'})){
            window.Echo.private('offerRequest').listenToAll((event, data) => {
              // do what you need to do based on the event name and data
              this.loadNotifications();
              eventBus.$emit('offerRequest');
            });
          }


          window.Echo.join('users.all').here((members) => {
            this.$store.commit('setUsersOnline', members);
          }).joining(user => {
            this.$store.commit('setUserOnline', user);
          }).leaving(user => {
            this.$store.commit('setUserOffline', user);
          });
      },
      setLocale(locale){
          setI18nLanguage(i18n, locale);
          //this.langProp();
      },
      setModalHidden(hide){
          if(hide !== undefined){
              this.hideModal = hide;
              return;
          }

          this.hideModal = !this.hideModal;
      },
      hasRight(right){
          switch(right){
              case 'FiBu':
                  if(this.$auth.user().userrole_id == 1){
                      return true;
                  }
                  if(this.$auth.user().userrole_id == 2){
                      return true;
                  }
                  if(this.$auth.user().isAccountingAllowed === true){
                      return true;
                  }
                  return false;
              case 'settings':
                  if(this.$auth.user().isSettingsAllowed){
                      return true;
                  }
                  return false;
          }

          return false;
      },
      comments(modus){
        this.modalCommentModus = modus;
        this.modalComment = true;
      },
      createOffer(type, contact_id){
          this.axios.post('erp/offer/create', {type: type, contact_id: contact_id}).then(res => {
              this.$router.push({name: 'offer', params: {id: res.data.id}});
          });
      },
      createInvoice(){
        this.axios.post('fibu/invoices/create', {}).then(res => {
          this.$router.push({name: 'invoice', params: {id: res.data.id}});
        });
      },
      createArticleorder(contact_id){
        this.axios.post('erp/articleorders/create', {contact_id: contact_id}).then(res => {
          this.$router.push({name: 'articleOrder', params: {id: res.data.id}});
        });
      }
  }
}
</script>